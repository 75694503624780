<template>
  <div class="app-wrap">
    <h3>风险测评</h3>
    <template v-if="isShow === 1">
      <div class="notice-wrap">
        <img src="@/assets/images/myFavors/noticebg.png">
        <div class="notice-text">
          <h3>测评须知</h3>
          <p>· 测评结果分为5种，详阅《适当性匹配须知》。该适当性匹配意见不表明我公司对产品的风险和收益作出任何实质性判断或保证。  </p>
          <p>· 风险测评有效期为1年。</p>
          <p>· 若您的信息发生变化，请及时重测，购买时请关注风险承受能力与产品的匹配情况。</p>
        </div>
      </div>
      <div class="go-btn">
        <span
          class="st-btn"
          @click="getQuestions"
        >开始测评<i class="iconfont icon-fundmarkct-next" /></span>
      </div>
    </template>
    
    <template v-if="isShow === 2">
      <div class="notice-wrap h296">
        <img src="@/assets/images/myFavors/noticeBg1.png">
        <div class="notice-result">
          <h3>测评结果</h3>
          <div class="result">
            <span>{{ riskDics[result.riskLevel] }}</span>
            <span>上次测评时间: {{ (result.lastTestDate || '').slice(0, 10) }}</span>
          </div>
          <div class="tips">
            适合购买产品的风险等级为
          </div>
          <div class="rl">
            {{ result.fitProdForClient }}
          </div>
        </div>
      </div>
      <p class="rl-t">
        · 测评结果分为5种，详阅<span>《适当性匹配须知》</span>。该适当性匹配意见不表明我公司对产品的风险和收益作出任何实质性判断或保证。<br>
        · 风险测评有效期为1年。<br>
        · 若您的信息发生变化，请及时重测，购买时请关注风险承受能力与产品的匹配情况。<br>
      </p>
      <div class="go-btn">
        <span
          class="b-btn"
          @click="getQuestions"
        >重新测试</span>
        <span
          class="st-btn"
          @click="handleGo"
        >选购基金</span>
      </div>
    </template>
    
    <!-- 评测弹框 -->
    <zh-dialog
      ref="dialog"
      width="1000"
      height="80%"
      title="风险测评"
    >
      <div class="qs-content">
        <div class="main">
          <template v-for="(item, index) in questions">
            <div
              :key="item.subjectName"
              class="qs-title"
            >
              {{ index+1 }}. {{ item.subjectContent }}
            </div>
            <div
              :key="item.subjectId"
              class="qs-list"
            >
              <div
                v-for="(child, i) in item.answerList"
                :key="child.answerContent + i"
                class="qs-item"
              >
                <zh-radio
                  :value="answer[item.subjectId]"
                  :label="child.answerOption"
                  @input="(e) => handleCheck(e, item.subjectId)"
                >
                  {{ child.answerContent }}
                </zh-radio>
              </div>
            </div>
          </template>
        </div>
        <div class="bottom">
          <span
            class="sub-btn"
            @click="handleSubmit"
          >提交</span>
        </div>
      </div>
    </zh-dialog>
  </div>
</template>

<script>
import {available, rating, getRiskLevel} from '@/api/risk.js'
export default {
  data() {
    return {
      isShow: 0,
      questions: [],
      strategyId: '',
      answer: {},
      riskDics: {},
      result: {
        lastTestDate: '',
        fitProdForClient: '',
        riskLevel: ''
      }
    }
  },
  created() {
    const info = JSON.parse(localStorage.getItem('customerInfo')) || {}
    this.getDicts('hs_risk_Level').then(({data})=>{
      data.forEach(item => {
        this.$set(this.riskDics, item.dictValue, item.dictLabel)
      })
    });
    if (info.riskFlag == 1) {
      this.isShow = 2
      this.getRisk()
    }else {
      this.isShow = 1
    }
  },
  methods: {
    getRisk() {
      getRiskLevel().then(res => {
        this.result.lastTestDate = res.data.riskDate
        this.result.riskLevel = res.data.riskLevel
        this.result.fitProdForClient = res.data.fitProdForClient
      })
    },
    handleGo() {
      this.$router.push({
        name:'FundMarket'
      })
    },
    getQuestions() {
      available({investorType:1}).then(({data}) => {
        this.questions = data.saleSubjectInfo.map(item =>  {
          this.$set(this.answer, item.subjectId, '')
          return {
            ...item, 
            itemVal: ''
          }
        })
        this.strategyId = data.strategyId
        this.$refs['dialog'].open()
      }).catch(error => {
        this.$message.error(error)
      })
    },
    handleCheck(x, y) {
      this.answer[y] = x;
    },
    handleSubmit() {
      let flag = true
      const _answer =  Object.keys(this.answer).map(key => {
        if(this.answer[key] === '') {
          flag = false
        }
        return {
          subjectId: key,
          answerOption: this.answer[key]
        }
      })
      // 校验是否全部填写完毕
      if (!flag) {
        this.$message({
          message: '有未选择选项',
          type: 'warning'
        });
        return
      }
      rating({
        clientAnswerInfoDTOList: _answer,
        strategyId: this.strategyId
      }).then(res => {
        this.$store.dispatch('GetInfo').then(() => {
          this.result = res.data
          this.isShow = 2
          this.$refs['dialog'].close()
          this.$message({
            message: '评测成功',
            type: 'success'
          });
        })
        // this.result = res.data
        // this.isShow = 2
        // this.$refs['dialog'].close()
      })
    }
  },
}
</script>

<style scoped lang="less">
@import url('@/assets/css/zc-common.less');
.app-wrap > h3 {
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  color: #1F1F1F;
  padding-bottom: 30px;
  border-bottom: 1px solid #EDF1F7;
}
.notice-wrap {
  width: 100%;
  height: 352px;
  margin-top: 42px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  position: relative;
  &>img {
    width: 100%;
    height: 100%;
  }
  .notice-text {
    position: absolute;
    left: 40px;
    bottom: 36px;
    width: 403px;
    &>h3 {
      width: 100%;
      font-size: 18px;
      font-weight: bold;
      color: #CE9B63;
      text-align: center;
      margin-bottom: 38px;
    }
    p {
      font-size: 14px;
      color: #A5A5A5;
      margin-top: 28px;
    }
  }
  .notice-result {
    position: absolute;
    top: 30px;
    left: 40px;
    &>h3 {
      font-size: 18px;
      color: #6B6B6B;
    }
    .result {
      margin-top: 30px;
      min-height: 75px;
      .flex(center, flex-start, column);
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 22px;
      position: relative;
      &>span {
        &:first-child {
          font-size: 24px;
          color: #CE9B63;
        }
        &:last-child {
          margin-top: 20px;
          font-size: 14px;
          color: #BDC0CB;
        }
      }
      &::after {
        content: '';
        display: inline-block;
        width: 2px;
        height: 75px;
        background: linear-gradient(144.05deg, #F7D9B7 41.07%, #EABF96 98.95%);
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .tips {
      margin-top: 24px;
      font-size: 12px;
      color: #25293D;
    }
    .rl {
      font-size: 16px;
      color: #CE9B63;
      margin-top: 21px;
    }
  }
}
.h296 {
  height: 296px;
}
.rl-t {
  margin-top: 20px;
  font-size: 12px;
  color: #A5A5A5;
  span {
    color: #CE9B63;
  }
}
.go-btn {
  width: 100%;
  margin-top: 40px;
  text-align: right;
  .b-btn {
    .inline-flex(center, center, row);
    width: 150px;
    height: 46px;
    background: #FFFFFF;
    color: #CE9B63;
    font-size: 14px;
    border: 1px solid #CE9B63;
    box-sizing: border-box;
    border-radius: 2px;
    cursor: pointer;
  }
  .st-btn {
    .inline-flex(center, center, row);
    .btn (150px, 46px);
    margin-left: 18px;
    .iconfont {
      font-size: 10px;
      margin-left: 10px;
    }
  }
}
.qs-content {
  
  padding-bottom: 40px;
  width: 100%;
  height: 100%;
  .flex(center, center, column);
  .border-box();
  .main {
    padding: 0 30px;
    width: 100%;
    flex: 1;
    .border-box();
    overflow-y: auto;
    .qs-title {
      width: 100%;
      color: #1F1F1F;
      font-size: 16px;
      margin-bottom: 40px;
      margin-top: 30px;
    }
    .qs-list {
      .border-box();
      padding-left: 12px;
      width: 100%;
      .qs-item {
        margin-top: 30px;
      }
    }
  }
  .bottom {
    width: 100%;
    text-align: center;
    margin-top: 24px;
    height: 48px;
    .sub-btn {
      .inline-flex(center, center, row);
      .btn(450px, 48px)
    }
  }
}
</style>
