import request from '@/utils/request';
// 查询一套可用的风险策略给客户测评
export function available(data) {
  return request({
    url: '/api/sale/strategy/available',
    method: 'POST',
	data
  })
}

// 客户测评后评级
export function rating(data) {
  return request({
    url: '/api/sale/strategy/rating',
    method: 'POST',
	data
  })
}


// 获取评测结果
export function getRiskLevel(data) {
  return request({
    url: '/api/sale/customer/riskinfo',
    method: 'POST',
	data
  })
}